
  .img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border-color:peru;
    border-style:double;
    
    
  }
  #input[type="file"] {
    display: none;
  }
  .image-upload {
    height: 20px;
    color: black;
    padding: 5px 28px;
    background-color: #E5E5E5;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 700;
  }
  
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h2 {
  font-family: sans-serif;
  margin: 10px auto;
  color: #5d5d5d;
  text-align: center;
  font-size: 30px;
  max-width: "100%";
  position: relative;
}
h2:before {
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  background: #9b9b9b;
  left: 20px;
  top: 50%;
  position: absolute;
}
h2:after {
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  background: #9b9b9b;
  right: 20px;
  top: 50%;
  position: absolute;
}

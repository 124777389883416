$fontColor:#242424;
$fontSize1:14px;
$fontSize2:13px;
$fontSize3:10px;

h3 {
  font-family: sans-serif;
  margin: 10px auto;
  color: #5d5d5d;
  font-size: 30px;
  max-width: "100%";
  width: auto;
  position: relative;
}
.list1{
  .MuiTypography-root{
    font-size: $fontSize1;
    color: $fontColor;
  }
  .MuiSvgIcon-root{
    height: 22px;
    color: $fontColor;
  }
  .MuiListItemButton-root{
    padding: 4px 12px;
  }
}
.list2{
  .MuiTypography-root{
    font-size: $fontSize2;
  }
  .MuiListItemButton-root{
    padding: 4px 32px;
  }

}

.item_des{
  text-align: center;
  font-size: $fontSize1;
}
.item_price{
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(221, 20, 20);
}
.item_price::before{
  content: "৳";
  margin-right:2px;
  font-weight: 600;
}
.price::before{
  content: "৳";
  margin-right:2px;
  font-weight: 600;
}

.item_price2{
  font-family: "Sofia", sans-serif;
  letter-spacing: 2px;
  text-decoration: line-through;
  font-size: $fontSize3;
}
.menuicon{
color: rgb(26, 26, 26,0.6);
}
.searchicon{
  color: rgb(26, 26, 26,0.5);
}
.login_container *{
  font-size: 13px;
  color: rgb(26, 26, 26,0.7);
}
